import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomizationStoreService {
  //Get and save customization info in customizationData Observable
  private customizationData = new BehaviorSubject<{}>({});
  public customizationData$: Observable<{}> = this.customizationData.asObservable();
  
  constructor() { }

  setCustomizationDataValue(value: {}) {
    this.customizationData.next(value);
  }
}