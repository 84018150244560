import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SidebarComponent } from '../../../@vex/components/sidebar/sidebar.component';
import { getCopyrightName } from 'src/static-data/location-variables';
import { CustomizationService } from 'src/services/customization.service';
import { CustomizationStoreService } from 'src/services/customization.store.service';

@UntilDestroy()
@Component({
  selector: 'vex-widget-boxed-layout',
  templateUrl: './widget-boxed-layout.component.html',
  styleUrls: ['./widget-boxed-layout.component.scss']
})
export class WidgetBoxedLayoutComponent implements OnInit {
  @ViewChild('configpanel', { static: true }) configpanel: SidebarComponent;
  @ViewChild('widgetCard') widgetCard : ElementRef;
  widgetHeight: number = 0;
  copyrightName = '';
  userId;
  showCopyright = false;
  loadingCustomization = true;
  customizationData;

  constructor(
    private customizationService: CustomizationService,
    private customizationStoreService: CustomizationStoreService
  ) { }

  ngOnInit() {
    this.copyrightName = getCopyrightName();
    let arr = ('' + window.location).split('/');
    this.userId = arr && arr.length && arr[arr.length - 2] ? arr[arr.length - 2] : '';
    if(this.userId) {
      this.setCustomization();
    } else {
      this.showCopyright = true;
      this.loadingCustomization = false;
    }
  }

  ngDoCheck() {
    if (this.widgetCard && this.widgetHeight != this.widgetCard.nativeElement.offsetHeight) {
      // get widgetCard height
      this.widgetHeight = this.widgetCard.nativeElement.offsetHeight;
      // send widgetHeight to parent
      this.sendMessageToParent({type: 'widgetHeight', data: this.widgetHeight});
    }
  }

  setCustomization() {
    this.loadingCustomization = true;
    this.customizationService.getCustomization(this.userId).then((customization: any) => {
      this.customizationData = customization;
      // set customization observable
      this.customizationStoreService.setCustomizationDataValue(this.customizationData);
      
      if(this.customizationData && this.customizationData.policy) {
        this.showCopyright = false;
      } else {
        this.showCopyright = true;
      }
      this.loadingCustomization = false;
    }).catch(e => {
      this.showCopyright = true;
      this.loadingCustomization = false;
    })
  }

  gotoPolicy(e) {
    e.preventDefault();
    e.stopPropagation();
    window.open(window.location.origin + '/policy?userId=' + this.userId);
  }

  gotoLegal(e) {
    e.preventDefault();
    e.stopPropagation();
    window.open(window.location.origin + '/legal?userId='  + this.userId);
  }

  sendMessageToParent(obj) {
    window.top.postMessage(obj,'*')
  }
}
